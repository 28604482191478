<script>
export default {
  name: 'PageLogin',
  data: () => ({
    user: {
      email: '',
      password: '',
      firstname: '',
    },
  }),
  methods: {
    register() {
      const user = { ...this.user };
      this.$store.dispatch('auth/register', user)
        .then(() => {
          const name = this.$store.getters['auth/role'] ? 'home' : 'admin_index';
          this.$router.push({ name });
        });
    },
  },
};
</script>

<template>
  <section class="section">
    <form class="box" @submit.prevent="register">
      <b-field label="Prénom" label-for="firstname">
        <b-input v-model="user.firstname" id="firstname" required />
      </b-field>
      <b-field label="Email" label-for="email">
        <b-input v-model="user.email" type="email" id="email" required />
      </b-field>
      <b-field label="Mot de passe" label-for="password">
        <b-input v-model="user.password" type="password" id="password" password-reveal required />
      </b-field>
      <b-field>
        <b-button type="is-primary" native-type="submit" expanded>
          Inscription
        </b-button>
      </b-field>
    </form>
  </section>
</template>
